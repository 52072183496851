<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">

        <crm-table
          :headers="headers"
          :data="data"
          :config="config"
          @handleActions="handle"
        >
        </crm-table>
      </v-col>
    </v-row>
    <v-dialog v-model="modalVisible" max-width="60%"
              @click:outside="handleClose"
              @keydown.esc="handleClose"
    >
      <v-card>
        <v-card-title class="card-title elevation-3">
          <span class="mr-3">Добавить</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="handleClose">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-5">
          <v-container fluid>
            <v-col cols="12">
              <v-form v-model="valid" lazy-validation ref="form">
                <v-row v-for="(f, index) in modalFields" :key="index">
                  <component :is="f.type"
                             v-model="form[f.prop]"
                             :name="f.label"
                             :rules="f.rules"
                             :form="form"
                             :filterBy="f.filterBy"
                             :dictionaryList="f.dictionaryList">

                  </component>
                </v-row>
              </v-form>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end pb-5">
          <v-btn
            v-if="mode === 'create' || mode === 'edit'"
            @click="save"
            color="#007450"
            style="color: #FFFFFF; border-radius: 18px"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import crmTable from '@/components/crm-table/index.vue';
import * as components from '@/components/FormComponents/index';
import ModalForm from '@/components/ModalForm.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'index',
  components: {
    crmTable,
    ModalForm,
    ...components,
  },
  data() {
    return {
      headers: [
        {
          value: 'id', sortable: false, text: 'ID', type: 'defaultItem',
        },
        {
          value: 'nameRu', sortable: false, text: 'Наименование на рус.', type: 'defaultItem',
        },
        {
          value: 'nameKk', sortable: false, text: 'Наименование на каз.', type: 'defaultItem',
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          child: [
            {
              label: 'Редактировать',
              event: 'edit',
              icon: 'mdi-pencil',
              visible: true,
            },
          ],
        },
      ],
      data: [],
      form: {},
      valid: false,
      modalVisible: false,
      itemId: null,
      item: {},
      config: {
        add: true,
      },
      mode: 'create',
      modalFields: [
        {
          label: 'Наименование рус.', prop: 'nameRu', type: 'STRING', rules: [(v) => !!v],
        },
        {
          label: 'Наименование каз.', prop: 'nameKk', type: 'STRING', rules: [(v) => !!v],
        },
      ],
    };
  },
  computed: {
    ...mapState(['user']),
    isChief() {
      return this.user.roles.includes('ROLE_CHIEF');
    },
  },
  created() {
    this.get();
  },
  methods: {
    ...mapActions('dictionary', ['getData', 'removeData']),
    async get() {
      const resp = await this.$api.get('api/v1/sale/dictionary/color');
      this.data = [...resp];
    },
    async save() {
      if (this.$refs.form.validate()) {
        const el = this.form;
        if (this.mode === 'create') {
          const response = await this.$api.post('api/v1/sale/dictionary/color', {
            ...el,
          });
          if (response) {
            this.handleClose();
          }
        } else if (this.mode === 'edit') {
          el.id = this.itemId;
          const response = await this.$api.put('api/v1/sale/dictionary/color', {
            ...el,
          });
          if (response) {
            this.handleClose();
          }
        }
      }
    },
    handle(event) {
      switch (event.type) {
        case 'edit':
          if (this.isChief) break;
          this.itemId = event.item.id;
          this.form = this.data.find((i) => i.id === this.itemId);
          this.mode = 'edit';
          this.modalVisible = true;
          break;
        case 'create':
          if (this.isChief) break;
          this.modalVisible = true;
          this.mode = 'create';
          break;
        default:
          break;
      }
    },
    handleClose() {
      this.modalVisible = false;
      this.itemId = null;
      this.form = {};
      this.get(this.url);
    },
  },
};
</script>

<style lang="scss">

</style>
